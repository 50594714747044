import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

const LoadContent = () => {
  return (
    <div
      className="flex justify-center items-center"
      style={{ height: "calc(100vh - 78px)" }}
    >
      <LoadingOutlined className="text-dark-blue text-30" />
    </div>
  );
};

export default React.memo(LoadContent);
