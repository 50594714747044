import { Mutex } from "async-mutex";

import { ACCESS_TOKEN_KEY } from "@app/config/const";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import StorageServices from "@services/local.storage";

const clearSession = () => {
  localStorage.clear();
  sessionStorage.clear();
};

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_PROXY,
  timeout: 15000,
  prepareHeaders: (headers) => {
    const accessToken = StorageServices.getData(ACCESS_TOKEN_KEY, null);
    if (accessToken) headers.set("authorization", `Bearer ${accessToken}`);
    return headers;
  },
});

const mutex = new Mutex();
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    window.localStorage.clear();
    window.location.replace("/auth/sign-in");
    return result;
  }
  return result;
};

export const apiService = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
