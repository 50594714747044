import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "@store/index";
import { I18nextProvider } from "react-i18next";
import i18n from "@locale/index";
import AppProvider from "@provider/AppProvider";
import { ConfigProvider } from "antd";
import LoadData from "@components/common/LoadData";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <React.StrictMode>
      <Router>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#13345F",
            },
          }}
        >
          <AppProvider>
            <App />
            <LoadData />
          </AppProvider>
        </ConfigProvider>
      </Router>
      </React.StrictMode>
    </I18nextProvider>
  </Provider>
);
