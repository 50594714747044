import { routes } from "@app/router";
import PrivateRoute from "@app/router/PrivateRoute";
import PublicRoute from "@app/router/PublicRoute";
import DevelopingView from "@components/common/DevelopingView";
import NotFound from "@views/not-found";
import { Route, Routes } from "react-router-dom";
import "antd/dist/reset.css";
import "@assets/styles/main.scss";
import CommonLayout from "@components/layout/CommonLayout";

function App() {
  return (
    <Routes>
      <Route path="/" element={<CommonLayout />}>
        {routes.map((route) => {
          return (
            <Route
              key={route.key}
              path={route.path}
              element={
                route.ready ? (
                  route.public ? (
                    <PublicRoute>
                      <route.component />
                    </PublicRoute>
                  ) : (
                    <PrivateRoute>
                      <route.component />
                    </PrivateRoute>
                  )
                ) : (
                  <DevelopingView />
                )
              }
            />
          );
        })}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
