import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  initialState: null,
  reducers: {
    update(_, action: PayloadAction<User | null>) {
      return action.payload as any;
    },
  },
  name: "user",
});

export const { update } = userSlice.actions;

export default userSlice.reducer;
