import { combineReducers } from "@reduxjs/toolkit";
import { apiService } from "./api/base";
import auth from "./slices/auth.slice";
import user from "./slices/user.slice";
const rootReducer = combineReducers({
  auth,
  user,
  [apiService.reducerPath]: apiService.reducer,
});

export default rootReducer;
