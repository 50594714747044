import { update } from "@store/slices/user.slice";
import store from "..";
import { apiService } from "./base";

export interface UpdateUserBody {
  fullName: string;
  mobileNumber: string;
  address: string;
  sex: number;
  bod: string;
}

const userApi = apiService.injectEndpoints({
  endpoints: (build) => ({
    me: build.mutation<Response<User>, any>({
      query: () => ({
        url: "/v1.0/auth/me",
        method: "GET",
      }),
      transformResponse: (res: Response<User>, meta, arg) => {
        store.dispatch(update(res.data));
        return res;
      },
    }),
    updateUser: build.mutation<Response<User>, UpdateUserBody>({
      query: (body) => ({
        url: "/v1.0/users/crud/me/update/info",
        method: "PUT",
        body,
      }),
    }),
    updateAvatar: build.mutation<Response<User>, any>({
      query: (body) => ({
        url: "/v1.0/users/crud/update/avatar",
        method: "POST",
        body,
      }),
    }),
    changePassword: build.mutation<
      Response<User>,
      { oldPassword: string; newPassword: string; verifyPassword: string }
    >({
      query: (body) => ({
        url: "/v1.0/auth/change-password",
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useMeMutation,
  useUpdateUserMutation,
  useUpdateAvatarMutation,
  useChangePasswordMutation,
} = userApi;
