import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header";
import "./style.scss";

const AuthLayout = () => {
  return (
    <div>
      <div className="auth-header">
        <Header />
      </div>
      <div className="app-content-wrapper">
        <Outlet />
      </div>
      <div className="auth-footer">
        <img className="bg" src="/assets/image/footer-white.png" alt="img" />
        <img className="bg" src="/assets/image/footer-blue.png" alt="img" />
        <img
          className="dot left"
          src="/assets/image/footer-left.svg"
          alt="img"
        />
        <img
          className="dot right"
          src="/assets/image/footer-right.svg"
          alt="img"
        />
        <div className="footer-content-container">
          <div className="footer-content">
            <div className="item first">
              <div className="logo">
                <img src="/assets/image/logo-white.png" alt="icon" />
              </div>
              <div className="info flex items-center">
                <img src="/assets/image/phone.svg" alt="icon" />
                1900 636 929
              </div>
              <div className="info flex items-center">
                <img src="/assets/image/mail.svg" alt="icon" />
                info@iigvietnam.edu.vn
              </div>
              <div className="social flex">
                <img src="/assets/image/facebook.svg" alt="icon" />
                <img src="/assets/image/youtube.svg" alt="icon" />
                <img src="/assets/image/zalo.svg" alt="icon" />
              </div>
            </div>
            <div className="item second">
              <div className="title">ĐỊA CHỈ VĂN PHÒNG</div>
              <div className="subtitle">Trụ sở chính</div>
              <div className="content content-1">
                <img src="/assets/image/location.svg" alt="img" />
                <div>75 Giang Văn Minh, Quận Ba Đình, Hà Nội</div>
              </div>
              <div className="subtitle">Chi nhánh TP.Đà Nẵng</div>
              <div className="content">
                <img src="/assets/image/location.svg" alt="img" />
                <div>19 Hoàng Văn Thụ, Quận Hải Châu, Đà Nẵng</div>
              </div>
            </div>
            <div className="item third">
              <div className="title"> </div>
              <div className="subtitle">Địa điểm đăng ký thi tại Hà Nội</div>
              <div className="content content-1">
                <img src="/assets/image/location.svg" alt="img" />
                <div>
                  Tầng 3, Trung Yên Plaza, 1 Trung Hoà, Quận Cầu Giấy, Hà Nội
                </div>
              </div>
              <div className="subtitle">Chi nhánh TP.Hồ Chí Minh</div>
              <div className="content">
                <img src="/assets/image/location.svg" alt="img" />
                <div>
                  Tầng 1, Tháp 1, The Sun Avenue, 28 Mai Chí Thọ, An Phú, TP.Thủ
                  Đức, TP.HCM
                </div>
              </div>
            </div>
            <div className="item four">
              <div className="title">Thời gian làm việc</div>
              <div className="subtitle subtitle-1">
                Sáng : 08:00 - 12:00 <br />
                Thứ Hai - Thứ Bảy
              </div>
              <div className="subtitle">
                Chiều: 13:30 - 17:30 <br />
                Thứ Hai - Thứ Sáu
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AuthLayout);
