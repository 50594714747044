import { ACCESS_TOKEN_KEY } from "@app/config/const";
import StorageServices from "@services/local.storage";
import { useMeMutation } from "@store/api/user.api";
import { useAppSelector } from "@store/index";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const LoadData = () => {
  const [getMeData] = useMeMutation();
  const location = useLocation();
  const user = useAppSelector((state) => state.user);
  useEffect(() => {
    if (
      !user &&
      StorageServices.getData(ACCESS_TOKEN_KEY, null) &&
      !location.pathname.includes("/auth/")
    ) {
      getMeData(undefined);
    }
  }, [getMeData, location, location.pathname, user]);
  return <></>;
};
export default React.memo(LoadData);
