import { ACCESS_TOKEN_KEY } from "@app/config/const";
import LoadContent from "@components/common/LoadContent";
import StorageServices from "@services/local.storage";
import React, { Suspense, useEffect } from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }: any) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });
  if (!StorageServices.getData(ACCESS_TOKEN_KEY, null))
    return <Navigate to="/auth/sign-in" />;
  return <Suspense fallback={<LoadContent />}>{children}</Suspense>;
};

export default React.memo(PrivateRoute);
