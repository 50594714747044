import React from "react";
import { useLocation } from "react-router-dom";
import LearnLayout from "./LearnLayout";
import AppLayout from "./AppLayout";

const CommonLayout = () => {
  const { pathname } = useLocation();
  return pathname.includes("/lesson") ? <LearnLayout /> : <AppLayout />;
};

export default CommonLayout;
